import type { AbiItem } from 'web3-utils';
import { $context } from '@/store';

const getContract = (config: any) => {
    return new ($context.web3 as any).eth.Contract(config.ABI as unknown as AbiItem[], config.address);
}  

export const geteALEOTokenContract = () => {
    const eALEO = ($context.chainNetwork as any).contracts.eALEO;
    return getContract(eALEO);
}
export const geteIOPledgeContract = () => {
    const eioPledge = ($context.chainNetwork as any).contracts.ioPledge;
    return getContract(eioPledge);
}
export const geteUSDTExchangeContract = () => {
    const eUSDT = ($context.chainNetwork as any).contracts.eUSDTExchange;
    return getContract(eUSDT);
}
export const geteIOPointTokenContract = () => {
    const eIOPoint = ($context.chainNetwork as any).contracts.eIOPoint;
    return getContract(eIOPoint);
}
export const geteUSDTTokenContract = () => {
    const eUSDT = ($context.chainNetwork as any).contracts.eUSDT;
    return getContract(eUSDT);
}
export const getEFilTokenContract = () => {
    const eFil = ($context.chainNetwork as any).contracts.eFil;
    return getContract(eFil);
}

export const getRgpTokenContract = () => {
    const rgp = ($context.chainNetwork as any).contracts.rgp;
    return getContract(rgp);
}

export const getEFilChangeTokenContract = () => {
    const eFil = ($context.chainNetwork as any).contracts.erc20;
    return getContract(eFil);
}

export const getAMBTokenContract = () => {
    const AMB = ($context.chainNetwork as any).contracts.AMB;
    return getContract(AMB);
}

export const getSUBTokenContract = () => {
    const sub = ($context.chainNetwork as any).contracts.sub;
    return getContract(sub);
}

export const getRRMTokenContractByVersion = (version?: string) => {
    const contractConfig = ($context.chainNetwork as any).contracts?.rrmToken;
    if (!contractConfig) {
      console.log("No rrmtoken contract config.");
    }
    if (version && $context.contractVersions) {
        let address = $context.contractVersions.get(version);
        let abi = contractConfig?.abiMap[version as keyof object];
        console.log('get contract', address, version);
        return getContract({
            ABI: abi,
            address: address
        });
    } else {
        return getContract(contractConfig);
    }
}

export const getDepositsContract = () => {
    const contractConfig = ($context.chainNetwork as any).contracts?.deposits;
    if (!contractConfig) {
      console.log("No deposits contract config.");
    }

    return getContract(contractConfig);
}